import { useState, useEffect } from 'react';
import axios from 'axios';
import { URL } from '../../config';
const useTimeslots = (selectedDate, experienceId, key) => {
  const [timeslots, setTimeslots] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTimeslots = async () => {
      try {
        if (selectedDate) {
          const inputDate = new Date(selectedDate);
          const year = inputDate.getFullYear();
          const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
          const day = inputDate.getDate().toString().padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
          const dateParam = `&date=${formattedDate}`;
          const expIdParam = experienceId > 0 ? `&expId=${experienceId}` : '';
          const response = await axios.get(`${URL}/widget/timeslots?${dateParam}${expIdParam}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${key}`
            }
          });

          const availableTimeSlots = response.data.data.availableTimeSlots;
          if (availableTimeSlots.length > 0) {
            setTimeslots(availableTimeSlots);

            const defaultTime = availableTimeSlots[0].value;
            setSelectedTime(defaultTime);
          } else {
            setTimeslots([]);
            setSelectedTime('');
          }
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchTimeslots();
  }, [selectedDate, experienceId, key]);

  return {
    timeslots,
    selectedTime,
    loading,
    error,
    setSelectedTime
  };
};

export default useTimeslots;
