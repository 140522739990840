import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Container, Button, IconButton } from '@material-ui/core';
import { s3Bucket, wabiUrl } from '../../config';
import { BiCalendar, BiMoney, BiMap, BiMinus, BiPlus, BiHome } from 'react-icons/bi';
import { BsTicketPerforated } from 'react-icons/bs';
import BookingModal from '../../components/booking/BookingModal';
import { Link, useParams } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import GoogleMap from '../../components/booking/GoogleMap';
import { RiRestaurantLine } from 'react-icons/ri';
import { PiWarningOctagonBold } from 'react-icons/pi';
const Experience = ({ location }) => {
  const {
    dayOfWeek,
    endDate,
    startDate,
    experienceDescription,
    experienceId,
    experienceName,
    experiencePhoto,
    price,
    tickets,
    sold,
    businessAddress,
    businessName,
    businessPhoneNo,
    widgetMessageTitle,
    widgetMessage,
    partySizes,
    onlinePaymentFee,
    shiftName,
    shiftUntilTime,
    shiftTimeRange,
    isOnlineEnabled
  } = location.state;
  const { key } = useParams();

  const findTimeRangeLabel = (untilTime, timeRangeArray) => {
    const matchingTimeRange = timeRangeArray.find((range) => range.value === untilTime);
    return matchingTimeRange ? matchingTimeRange.label : null;
  };

  const daysOfWeekNames = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  const convertToDayNames = (days) => {
    if (days.length === 0) {
      return 'Everyday';
    } else if (days.length === 1) {
      return `Every ${daysOfWeekNames[days[0] - 1]}`;
    } else if (days.length === 2) {
      return `Every ${daysOfWeekNames[days[0] - 1]} and ${daysOfWeekNames[days[1] - 1]}`;
    } else {
      const lastDay = days.pop();
      const daysString = days.map((day) => daysOfWeekNames[day - 1]).join(', ');
      return `Every ${daysString} and ${daysOfWeekNames[lastDay - 1]}`;
    }
  };
  const [guestCount, setGuestCount] = useState(1);
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const handleDecrement = () => {
    if (guestCount > 1) {
      setGuestCount(guestCount - 1);
    }
  };

  const handleIncrement = () => {
    const maxPartySize = partySizes[partySizes.length - 1];
    if (guestCount < maxPartySize) {
      setGuestCount(guestCount + 1);
    }
  };

  const handleOpenBookingModal = () => {
    setOpenBookingModal(true);
  };

  const handleCloseBookingModal = () => {
    setOpenBookingModal(false);
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '100px' }}>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12} md={12} xl={12}>
          <Typography variant="subtitle2">
            <Link to={`/${key}`}>
              <BiHome /> Home{' '}
            </Link>
            / <span style={{ fontWeight: 'bold' }}>{experienceName}</span>
          </Typography>
          <Typography
            variant="h4"
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              fontWeight: 'bolder',
              textTransform: 'uppercase'
            }}
          >
            {experienceName}
          </Typography>
          <Typography variant="subtitle2" style={{ marginTop: '10px', marginBottom: '10px' }}>
            {businessName} · {businessAddress} · {businessPhoneNo}
          </Typography>
        </Grid>
        <Grid item lg={8}>
          <img
            src={s3Bucket + experiencePhoto}
            alt={experienceName}
            onError={(e) => {
              e.target.src = 'https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg';
            }}
            style={{ width: '100%' }}
          />
          <Typography
            variant="body1"
            gutterBottom
            style={{ padding: '10px', borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}
          >
            {experienceDescription}
          </Typography>
          <Typography
            variant="h5"
            style={{
              padding: '10px',
              padding: '20px',
              fontWeight: 'bolder'
            }}
          >
            What to know before you arrive
          </Typography>
          <Typography
            variant="body2"
            style={{ padding: '10px', borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}
          >
            {widgetMessage}
          </Typography>
          <GoogleMap businessName={businessName} businessAddress={businessAddress} />
        </Grid>
        <Grid item lg={4} md={12} xs={12}>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            style={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#E7E7E7',
              borderRadius: '10px'
            }}
          >
            <Box style={{ borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}>
              <Typography>Event Details</Typography>
            </Box>
            {isOnlineEnabled ? (
              <>
                <Box style={{ borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}>
                  <Typography>
                    <BiCalendar style={{ marginRight: '20px', fontSize: '25px' }} />
                    {convertToDayNames(dayOfWeek)}
                  </Typography>
                </Box>
                <Box style={{ borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}>
                  <Typography>
                    <RiRestaurantLine style={{ marginRight: '20px', fontSize: '25px' }} />
                    {shiftName}
                  </Typography>
                </Box>
                <Box style={{ borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}>
                  <Typography>
                    <BsTicketPerforated style={{ marginRight: '20px', fontSize: '25px' }} />
                    {tickets === 0
                      ? 'Unlimited'
                      : `${tickets - sold} ${tickets - sold === 1 ? 'Ticket' : 'Tickets'}`}
                  </Typography>
                </Box>
                <Box style={{ borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}>
                  <Typography>
                    <BiMoney style={{ marginRight: '20px', fontSize: '25px' }} />${price} per person
                  </Typography>
                </Box>
                <Box style={{ borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}>
                  <Typography>
                    <BiMap style={{ marginRight: '20px', fontSize: '25px' }} />
                    {businessAddress}
                  </Typography>
                </Box>
                {shiftUntilTime !== null && shiftUntilTime !== 0 && (
                  <Box style={{ borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}>
                    <Typography>
                      <PiWarningOctagonBold style={{ marginRight: '20px', fontSize: '25px' }} />
                      Available for reservation until{' '}
                      {findTimeRangeLabel(shiftUntilTime, shiftTimeRange)}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Typography style={{ fontStyle: 'italic', padding: '5px' }}>
                Online booking for this restaurant is not currently available. Please try to call
                the restaurant to book your reservation.
              </Typography>
            )}
          </Grid>
          {isOnlineEnabled ? (
            <>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E7E7E7',
                  borderRadius: '10px',
                  marginTop: '25px'
                }}
              >
                <Box
                  style={{
                    borderBottom: 'solid 1px #E7E7E7',
                    padding: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div>
                    <Typography variant="subtitle2">Party Size</Typography>
                    <Typography variant="subtitle1">{`${guestCount} Guest${
                      guestCount !== 1 ? 's' : ''
                    }`}</Typography>
                  </div>
                  <div>
                    <IconButton
                      aria-label="decrement"
                      onClick={handleDecrement}
                      disabled={guestCount === 1}
                    >
                      <BiMinus />
                    </IconButton>
                    <IconButton aria-label="increment" onClick={handleIncrement}>
                      <BiPlus />
                    </IconButton>
                  </div>
                </Box>

                <Box style={{ borderBottom: 'solid 1px #E7E7E7', padding: '20px' }}>
                  <Button
                    variant="contained"
                    style={{
                      background: 'linear-gradient(180deg, #FD6C57, #FD3366 100%)',
                      color: '#ffffff',
                      width: '100%',
                      padding: '20px',
                      fontWeight: 'bolder'
                    }}
                    onClick={handleOpenBookingModal}
                  >
                    Get Tickets
                  </Button>
                </Box>
              </Grid>
            </>
          ) : (null)}
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center" style={{ marginTop: '80px' }}>
        <Typography variant="subtitle2" color="textSecondary">
          <span style={{ fontWeight: 'bolder' }}>Powered By</span>{' '}
          <a href={wabiUrl} target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Wabi" style={{ marginLeft: '5px', marginBottom: '3px' }} />
          </a>
        </Typography>
      </Grid>
      <BookingModal
        open={openBookingModal}
        close={handleCloseBookingModal}
        handleDecrement={handleDecrement}
        handleIncrement={handleIncrement}
        guestCount={guestCount}
        dayOfWeek={dayOfWeek}
        experienceName={experienceName}
        experienceId={experienceId}
        onlinePaymentFee={onlinePaymentFee}
        widgetMessageTitle={widgetMessageTitle}
        widgetMessage={widgetMessage}
        businessName={businessName}
        experiencePrice={price}
        startDate={startDate}
        endDate={endDate}
        shiftUntilTime={shiftUntilTime}
        shiftTimeLabel={findTimeRangeLabel(shiftUntilTime, shiftTimeRange)}
      />
    </Container>
  );
};
export default Experience;
