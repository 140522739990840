import React, { useEffect, useState } from 'react';
import { getGooglePlaces } from '../../hooks/@booking/useGooglePlaces';
import { Grid, Typography } from '@material-ui/core';
import { googleMapKey } from '../../config';

const GoogleMap = ({ businessName, businessAddress }) => {
  const [placeId, setPlaceId] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (businessName && businessAddress) {
        const params = { textQuery: `${businessName}, ${businessAddress}` };

        try {
          const response = await getGooglePlaces(params);

          if (response.status === 200) {
            setPlaceId(response?.data.id);
          }
        } catch (error) {
          console.error('Error fetching Google Places:', error);
        }
      }
    };
    fetchData();
  }, [businessAddress, businessName]);

  useEffect(() => {
    setEmbedUrl(placeId ? `https://www.google.com/maps/embed/v1/place?key=${googleMapKey}&q=place_id:${placeId}` : null);
  }, [placeId]);

  return (
    <>
      {placeId && (
        <>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              padding: '10px',
              padding: '20px',
              fontWeight: 'bolder',
            }}
          >
            More information
          </Typography>
          <Grid container style={{ marginLeft: '10px' }}>
            <iframe
              title="Embedded Google Map"
              width="100%"
              height="450"
              style={{ border: 0 }}
              src={embedUrl}
              allowFullScreen
            ></iframe>
          </Grid>
        </>
      )}
    </>
  );
};

export default GoogleMap;