import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    formatDate,
    parseDate,
    moment
} from 'react-day-picker/moment';
import logo from './../assets/images/logo.png';
import CalendarIcon from './../assets/images/icon-feather-calendar.svg';
import TimeIcon from './../assets/images/icon-material-access-time.svg';
import CheckMark from './../assets/images/success.png'
import PeopleIcon from './../assets/images/icon-material-people-outline.svg';
import AppleWalletIcon from './../assets/images/add-to-apple-wallet.svg';
import GoogleWalletIcon from './../assets/images/add-to-google-pay.svg';
import { withStyles } from "@material-ui/core/styles";
import './step3.css';
import axios from 'axios';
import { URL } from './../config';
import Spinner from 'react-bootstrap/Spinner';

const styles = theme => ({
    root: {
        display: "block",
    },
    formControl: {
        minWidth: 120
    }
});

class Step3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            key : this.props.match.params.key,
            title : document.getElementById('bookingWidgetRoot').getAttribute("data-title"),
            bookingId: this.props.match.params.booking_id,
            createdAt: null,
            formId: "updateBooking",
            payment: null,
            appleWalletUrl: null,
            googleWalletUrl: null,
            downloadId: 0,
            isLoading: false
        }
    }

    componentWillMount(){
        if (this.props.location.bookingData !== undefined) {
            this.setState({
                bookingDate: this.props.location.bookingData.date,
                partySize: this.props.location.bookingData.partySize,
                time: this.props.location.bookingData.time,
                createdAt: this.props.location.bookingData.createdAt,
                payment: this.props.location.payment,
                title: this.props.location.bookingData.title,
                appleWalletUrl: this.props.location.bookingData.appleWalletUrl,
                googleWalletUrl: this.props.location.bookingData.googleWalletUrl,
                downloadId: this.props.location.bookingData.downloadId
            });
        } else {
            this.props.history.push({
                pathname: `/${this.state.key}`
            });
        }
    }

    downloadPass = (type) => {
        this.setState({
            isLoading: true
        })
        const data = {
            downloadId: this.state.downloadId
        };

        axios({
            method: 'POST',
            url: `${URL}/widget/download_litecard`,
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.state.key}`
            },
            data: data
          })
          .then((response) => {
            this.setState({
                isLoading: false
            })
            const data = response.data.data
            switch (type) {
                case "googlePass":
                    window.location.replace(data.downloadUri.googleLink)
                break;

                case "applePass":
                    window.location.replace(data.downloadUri.appleLink)
                break;
            
                default:
                    break;
            }
          })
          .catch((error) => {
            // Do something when there is an error
            this.setState({
                isLoading: false
            })
        });
    };

    render() {
        return (
        <div className="booking_widget__app step3">
                <div className="content">
                    <div className="check-container">
                        <img src={CheckMark}></img>
                    </div>
                    <div className="text-center"><h2>Your table has been confirmed.</h2></div>

                    <div className="text-center title">
                        <h2>{this.state.title}</h2>
                    </div>

                    <div className="text-center icons-wrap">
                        <div className="icons">
                            <img src={CalendarIcon} alt="logo" />
                            <span>{this.state.bookingDate}</span>
                        </div>
                        <div className="icons">
                            <img src={TimeIcon} alt="logo" />
                            <span>{this.state.time}</span>
                        </div>
                        <div className="icons">
                            <img src={PeopleIcon} alt="logo" />
                            <span>{this.state.partySize} guests</span>
                        </div>
                    </div>

                    <Row>
                        <Col xs lg="12" className="text-center">
                            <p className="booking_widget__powered_by">
                                Powered by {" "}
                                <a
                                    href="https://javascriptpros.com"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img src={logo} alt="logo" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                    {this.state.isLoading ? (
                        <Row>
                            <Col fluid style={{ 
                                textAlign: 'center'
                            }}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                <div>
                                    <a onClick={() => this.downloadPass("applePass")}>
                                        <img src={AppleWalletIcon} alt="appleWallet"/>
                                    </a>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <a onClick={() => this.downloadPass("googlePass")}>
                                        <img src={GoogleWalletIcon} alt="appleWallet"/>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    )}
                    
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Step3);