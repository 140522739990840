// SelectComponent.js
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MdOutlinePeopleAlt } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";

function SelectComponent({ labelId, value, onChange, options, className, icon }) {
  const Icon = icon === 'people' ? MdOutlinePeopleAlt : MdOutlineAccessTime;
  return (
    <Select
      labelId={labelId}
      value={value}
      onChange={onChange}
      className={className}
      IconComponent={() => <Icon style={{ fontSize: 35, color: '#707070', marginRight: 10 }} />}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectComponent;