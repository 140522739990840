import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Box, Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { eachDayOfInterval, format } from 'date-fns';
import AvailableDate from '../../components/availability/AvailableDate';
import MessageDialog from '../../components/common/MessageDialog';
import { getRoster } from '../../hooks/@roster/useRoster';
import { getShifts } from '../../hooks/@roster/getShifts';
import { daysOfWeekNames } from '../../common/daysOfWeekNames';
import { postEmployeeAvailability } from '../../hooks/@roster/useAvailability';
import { DAYOFWEEK } from '../../common/enums/enum';
import { ReactComponent as Icon } from '../../assets/images/icon-wabi.svg';
import { getShiftColorCode } from '../../common/utility';
import '../../assets/css/roster/app.css';

const AvailabilityRequest = () => {
  const [beforeFormatingData, setBeforeFormatingData] = useState({});
  const [myShift, setMyShift] = useState([]);
  const [dayoff, setDayoff] = useState(0);
  const [fullName, setFullName] = useState('');
  const [availabilityLists, setAvalilableShifts] = useState({
    data: null,
    status: null,
    error: null,
    isLoading: true
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const schedule_id = searchParams.get('employee_schedule_date_id');
  const token = searchParams.get('token');
  const employee_id = searchParams.get('employee_id');
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogDescription, setDialogDescription] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [roster, setRoster] = useState({
    data: null,
    status: null,
    error: null,
    isLoading: true
  });
  useEffect(() => {
    if (roster.data) {
      const startDate = new Date(roster.data.calendar.start_date);
      const endDate = new Date(roster.data.calendar.end_date);
      let allShiftsExist = true;

      for (
        let currentDate = new Date(startDate);
        currentDate <= endDate;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD形式に変換
        if (
          !roster.data.my_shift[employee_id].shifts[formattedDate] ||
          roster.data.my_shift[employee_id].shifts[formattedDate].length === 0
        ) {
          allShiftsExist = false;
          break;
        }
      }

      if (allShiftsExist) {
        setIsSubmitted(true);
      }
    }
  }, [roster]);
  useEffect(() => {
    const fetchGetShiftsData = async () => {
      try {
        const response = await getShifts(token);
        switch (response.status) {
          case 200:
            setAvalilableShifts({
              data: response.data,
              status: response.status,
              error: null,
              isLoading: false
            });
            break;
          default:
            // Handle other response statuses here
            break;
        }
      } catch (error) {
        setAvalilableShifts({
          data: null,
          status: error.status,
          error: error.error,
          isLoading: false
        });
      }
    };
    fetchGetShiftsData();
  }, []);

  useEffect(() => {
    const fetchRosterData = async () => {
      try {
        const response = await getRoster(schedule_id, employee_id, token);
        switch (response.status) {
          case 200:
            setRoster({
              data: response.data,
              status: response.status,
              error: null,
              isLoading: false
            });
            break;
          default:
            break;
        }
      } catch (error) {
        setRoster({
          data: null,
          status: error.status,
          error: error.error,
          isLoading: false
        });
      }
    };
    fetchRosterData();
  }, [schedule_id, employee_id]);

  useEffect(() => {
    if (roster.data) {
      const myShifts = roster.data.my_shift[employee_id];

      const userName = myShifts ? myShifts.first_name + ' ' + myShifts.last_name : '';
      setFullName(userName);

      const dates = eachDayOfInterval({
        start: new Date(roster.data.calendar.start_date),
        end: new Date(roster.data.calendar.end_date)
      });

      dates.forEach((date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        const shiftsOnDate = myShifts.shifts[formattedDate];
        if (shiftsOnDate) {
          setMyShift((prevShifts) => [
            ...prevShifts,
            {
              dayOfWeek: format(date, 'EEEE').toUpperCase(),
              date: date,
              availabilityLists: []
            }
          ]);
        }
      });
    }
  }, [roster, employee_id]);

  const handleDateSelect = (dayOfWeek, availableData) => {
    const updatedData = { ...beforeFormatingData, [dayOfWeek]: availableData };
    setBeforeFormatingData(updatedData);
  };

  const isSubmitActive =
    Object.keys(beforeFormatingData).length === daysOfWeekNames.length - dayoff;

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };
  async function handlePostAbailability() {
    if (!isSubmitActive) {
      setDialogTitle('Availability Registration');
      setDialogDescription('Please register your availability for work on all days of the week.');
      handleOpenDialog();
      return;
    }
    const employeeShifts = Object.keys(beforeFormatingData).map((day) => ({
      date: beforeFormatingData[day].date,
      type: beforeFormatingData[day].type,
      shiftIds: beforeFormatingData[day].shiftIds,
      note: beforeFormatingData[day].note
    }));
    const param = {
      employee_schedule_date_id: schedule_id,
      employee_id: employee_id,
      employee: employeeShifts
    };
    const res = await postEmployeeAvailability(param, token);
    if (res.status === 200) {
      setDialogTitle('Availability Update Submitted');
      setDialogDescription('Your availability for this week has been successfully submitted.');
      handleOpenDialog();
      setIsSubmitted(true);
    } else {
      setDialogTitle('Submission Failed');
      setDialogDescription('An error occurred while attempting to submit. Please try again later.');
      handleOpenDialog();
    }
  }

  const arrayShifts = availabilityLists.data;
  const [formatShift, setFormatShift] = useState([]);
  useEffect(() => {
    let array = [];
    if (myShift && arrayShifts) {
      for (let i = 0; i < myShift.length; i++) {
        let shift = myShift[i];
        let allArray = [];

        for (let j = 0; j < arrayShifts.length; j++) {
          let arrayShift = arrayShifts[j];
          if (arrayShift.day_of_week.includes(DAYOFWEEK[shift.dayOfWeek])) {
            let availableShift = {
              id: arrayShift.id,
              name: arrayShift.name,
              color: getShiftColorCode(arrayShift.name)
            };
            shift.availabilityLists.push(availableShift);
            allArray.push(arrayShift.id);
          }
        }

        if (shift.availabilityLists.length > 0) {
          shift.availabilityLists.unshift({
            id: allArray,
            name: 'All Day',
            className: 'allday',
            color: '#AAFAA1',
            onlyOneChip: true
          });
          shift.availabilityLists.push({
            id: null,
            name: 'Request Off',
            className: 'off',
            color: '#757576',
            onlyOneChip: true,
            type: 2
          });
        } else {
          setDayoff(dayoff + 1);
        }
        array = [...array, shift];
      }
      setFormatShift(array);
    }
  }, [myShift, arrayShifts]);

  return (
    <div className="roster_root">
      <Container maxWidth="md">
        <Icon style={{ marginTop: '16px' }} />
        {isSubmitted ? (
          <Grid container className="roster_container">
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Box className="roster_header">
                <Typography variant="h6" display="block" gutterBottom align="center">
                  Hey {fullName} 👋
                </Typography>
                <Typography variant="subtitle1" display="block" gutterBottom align="center">
                  Thank you for submitting
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container className="roster_container">
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Box className="roster_header">
                <Typography variant="h6" display="block" gutterBottom align="center">
                  Hey {fullName} 👋
                </Typography>
                <Typography variant="subtitle1" display="block" gutterBottom align="center">
                  Please let us know your availability request for the week{' '}
                  {roster.data ? roster.data.calendar.roster_date : ''}
                </Typography>
              </Box>
            </Grid>
            {formatShift.length && roster.data ? (
              <>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  {formatShift.map((shift, index) => (
                    <AvailableDate
                      key={index}
                      shiftData={shift}
                      onSelect={(dayOfWeek, availableData) =>
                        handleDateSelect(dayOfWeek, availableData)
                      }
                      propData={
                        roster.data.my_shift[employee_id].shifts[format(shift.date, 'yyyy-MM-dd')]
                      }
                    />
                  ))}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={`roster_function_button ${
                      !isSubmitActive ? 'roster_function_button__disabled' : ''
                    }`}
                    onClick={handlePostAbailability}
                  >
                    Submit Availability
                  </Button>
                </Grid>
              </>
            ) : (
              <div>Loading...</div>
            )}
          </Grid>
        )}
        <MessageDialog
          open={isOpenDialog}
          onClose={() => handleCloseDialog()}
          title={dialogTitle}
          description={dialogDescription}
        />
      </Container>
    </div>
  );
};

export default AvailabilityRequest;
