import axios from 'axios';
import { URL } from '../../config';

export const postEmployeeAvailability = async (params, key) => {
  try {
    const response = await axios.post(`${URL}/widget/submit_availability`, params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${key}`,
      },
    });
    const res = {
      data: response.data,
      status: response.status,
      error: null,
      isLoading: false,
    };

    return res;
  } catch (error) {
    console.error('Error post availability:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false,
    };

    return res;
  }
};