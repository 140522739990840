const CURRENT_YEAR = (new Date()).getFullYear()
const URL = process.env.REACT_APP_API_URL;
//const URL = "https://api.wabify.com/api";
// const URL = "http://127.0.0.1:8000/api";
// const URL = "https://staging-api.wabify.com/api";
// const URL = "https://qa-api.wabify.com/api";
// const URL = process.env.REACT_APP_API_URL;
//const spriteKey = process.env.REACT_APP_STRIPE_API_KEY;
const spriteKey = `${process.env.REACT_APP_STRIPE_API_KEY}`;
const s3Bucket = process.env.REACT_APP_S3_URL;
const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
const wabiUrl = process.env.REACT_APP_WABI_URL;
const googleUrl = process.env.REACT_APP_GOOGLE_API_URL;
export {
    CURRENT_YEAR,
    URL,
    spriteKey,
    s3Bucket,
    googleMapKey,
    wabiUrl,
    googleUrl
}