import axios from 'axios';
import { googleMapKey, googleUrl } from '../../config';

export const getGooglePlaces = async (params) => {
  try {
    const response = await axios.post(`${googleUrl}/places:searchText`, params, {
      headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': `${googleMapKey}`,
        'X-Goog-FieldMask': 'places.id',
      },
    });
    return {
      data: response.data.places[0],
      status: response.status,
      error: null,
      isLoading: false,
  }
  } catch (error) {
    console.error('Error google maps:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false,
    };

    return res;
  }
};