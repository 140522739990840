import React from 'react';
import moment from 'moment';

class Countdown extends React.Component {
	state = {
		days: undefined,
		hours: undefined,
		minutes: undefined,
		seconds: undefined
	}
	
	componentDidMount() {
		const { secondsRemaining } = this.props;
		const duration = moment.duration(secondsRemaining, 's');

		this.interval = setInterval(() => {
			duration.subtract(1, "s");

			const minutes = duration.minutes();
			const seconds = duration.seconds();

			this.setState({ minutes, seconds });

			if (duration.asMilliseconds() == 0) {
				clearInterval(this.interval);
				
				console.warn("Times up!");
			}
		}, 1000);
	}

	componentWillUnmount() {
		if(this.interval) {
			clearInterval(this.interval);
		}
	}
	
	render() {
		const { days, hours, minutes, seconds } = this.state;
		const daysRadius = mapNumber(days, 30, 0, 0, 360);
		const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
		const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
		const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

		if(!seconds) {
			return (
				<div className="counter">
					You can still try to complete your reservation, but this table may no longer be available.
				</div>
			);
		}
		
		return (
			<div className="counter">
				<div className='countdown-wrapper'>
					<div className='countdown-item'>
						Due to limited availability, we can hold this table for you for {minutes}:{seconds} 
						<span>minutes</span>
					</div>
				</div>
			</div>
		);
	}
}

// Stackoverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
	return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export default Countdown;