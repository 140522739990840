import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  Slide,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core/';
import { MdOutlineClose } from 'react-icons/md';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SelectComponent from '../../components/utils/SelectComponent';
import '../../assets/css/booking/bookingModal.css';

//utils
import { addDays, getDay, isAfter } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { formatDate } from 'react-day-picker/moment';

//styles
import useStyles from '../../styles/booking/styles';

//hooks
import useTimeslots from '../../hooks/@booking/useTimeslots';
import useAvailableTimeSlots from '../../hooks/@booking/useAvailableTimeSlots';
import { createBooking } from '../../hooks/@booking/useBooking';

import BounceLoader from 'react-spinners/BounceLoader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 500 }} />;
});

const BookingModal = ({
  open,
  close,
  handleDecrement,
  handleIncrement,
  guestCount,
  dayOfWeek,
  experienceName,
  experienceId,
  onlinePaymentFee,
  widgetMessageTitle,
  widgetMessage,
  businessName,
  experiencePrice,
  startDate,
  endDate,
  shiftUntilTime,
  shiftTimeLabel
}) => {
  const { key } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [showPrompt, setShowPrompt] = useState(false);
  const [filteredTimeSlots, setfilteredTimeSlots] = useState([]);
  const [promptMessage, setPromptMessage] = useState('');
  const {
    availableTimeSlot,
    loadAvailableTime,
    loadingChanges,
    setAvailableTimeSlot,
    displayNoResultFound,
    displayHasSpecialDay,
    displayHasNoShift
  } = useAvailableTimeSlots(key);
  const availableTimeSlotRef = useRef(false);
  const shouldDisableDate = (date) => {
    const isCorrectDayOfWeek = dayOfWeek.includes(date.getDay());
    return !isCorrectDayOfWeek;
  };

  const [bookingClose, setbookingClose] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const { timeslots, selectedTime, setSelectedTime } = useTimeslots(
    selectedDate,
    experienceId,
    key
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    setAvailableTimeSlot([]);
    setShowPrompt(false);
    setPromptMessage(null);
    availableTimeSlotRef.current = false;
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
    setShowPrompt(false);
    setPromptMessage(null);
    availableTimeSlotRef.current = false;
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = getDay(currentDate);

    if (dayOfWeek.includes(currentDay)) {
      setSelectedDate(currentDate);
    } else {
      const nextDate = dayOfWeek
        .map((day) => addDays(currentDate, (7 + day - currentDay) % 7))
        .find((date) => isAfter(date, currentDate));

      setSelectedDate(nextDate || currentDate);
    }
  }, [dayOfWeek]);

  useEffect(() => {
    availableTimeSlotRef.current = false;
    setShowPrompt(false);
    setPromptMessage(null);
  }, [guestCount]);
  useEffect(() => {
    if (guestCount && selectedDate && selectedTime && !availableTimeSlotRef.current) {
      loadAvailableTime(guestCount, selectedDate, selectedTime, experienceId);
      availableTimeSlotRef.current = true;
    }
  }, [guestCount, selectedDate, selectedTime, loadAvailableTime, experienceId]);

  const checkBookingClose = () => {
    if (shiftUntilTime && selectedDate) {
      const isCurrentDate = new Date().toDateString() === new Date(selectedDate).toDateString();

      if (isCurrentDate) {
        const dateTimeOptions = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        };
        const dateOptions = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        };
        const currentDate = new Date().toLocaleString('en-US', dateTimeOptions);
        const formattedDate = new Date(selectedDate).toLocaleDateString('en-US', dateOptions);
        const date = `${formattedDate}, ${shiftTimeLabel}`;
        const result = new Date(date).setMilliseconds(0) < new Date(currentDate).setMilliseconds(0);
        setbookingClose(result);

      } else {
        setbookingClose(false);
      }
    }
  };

  useEffect(() => {
    checkBookingClose(); // Initial run

    const intervalId = setInterval(() => {
      checkBookingClose();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [selectedDate, shiftUntilTime, shiftTimeLabel]);

  async function handleCreateBooking(shiftId, floorId, floorName, userId, timeLabel, time) {
    const formattedSelectedDate = formatDate(selectedDate, 'YYYY-MM-DD');
    const formattedCurrentDate = formatDate(new Date(), 'YYYY-MM-DD');
    const currentTimeInSeconds = getCurrentTimeFormatted();
    let isValidTime = true;
    if(formattedSelectedDate === formattedCurrentDate) {
      if (time >= currentTimeInSeconds) {
        isValidTime = true;
      }else{
        isValidTime = false;
        setAvailableTimeSlot([]);
        setPromptMessage(null);
      }
    }

    if (isValidTime) {
      if (!bookingClose) {
        const data = {
          userId: userId,
          party_size: guestCount,
          date: formatDate(selectedDate, 'YYYY-MM-DD'),
          time: time,
          booking_time: time + '',
          shift_id: shiftId,
          experience_id: experienceId,
          experience_name: experienceName,
          price: experiencePrice,
          widgetNoteTitle: widgetMessageTitle,
          widgetNoteMessage: widgetMessage,
          onlinePaymentFee: onlinePaymentFee,
          floor_id: floorId + ''
        };

        const bookingResponse = await createBooking(data, key);
        if (bookingResponse.status !== 500) {
          const bookingData = {
            date: formatDate(selectedDate, 'dddd, D MMM'),
            time: timeLabel,
            partySize: bookingResponse.data.party_size,
            floor_name: floorName,
            createdAt: bookingResponse.data.created_at,
            shift_id: bookingResponse.data.shift_id,
            experience_id: experienceId,
            experience_name: experienceName,
            price: experiencePrice,
            widgetNoteTitle: widgetMessageTitle,
            widgetNoteMessage: widgetMessage,
            onlinePaymentFee: onlinePaymentFee,
            businessName: businessName
          };
          const newUrl = `/step2/${bookingResponse.data.id}/${key}`;

          history.push({
            pathname: newUrl,
            bookingData: bookingData
          });
        } else {
          setPromptMessage(bookingResponse.error);
          setShowPrompt(true);
        }
      }
    } else {
      setPromptMessage('Currently, this event is unavailable. Kindly choose an alternative date.');
      setShowPrompt(true);
    }
  }

  const today = new Date();
  const minDate = today > new Date(startDate) ? today : startDate;

  function convertTimeToSeconds(timeString) {
    const match = timeString.match(/(\d{1,2}):(\d{1,2})([APMapm]{2})/);

    if (!match) {
      return null;
    }

    const [hours, minutes, meridian] = match.slice(1, 4);
    let hoursIn24Format = parseInt(hours, 10);

    if (isNaN(hoursIn24Format) || isNaN(parseInt(minutes, 10))) {
      return null;
    }

    if (meridian.toLowerCase() === 'pm' && hoursIn24Format !== 12) {
      hoursIn24Format += 12;
    } else if (meridian.toLowerCase() === 'am' && hoursIn24Format === 12) {
      hoursIn24Format = 0;
    }

    const totalSeconds = hoursIn24Format * 3600 + parseInt(minutes, 10) * 60;
    return totalSeconds;
  }

  function getCurrentTimeFormatted() {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const meridian = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')}${meridian}`;
    return convertTimeToSeconds(formattedTime);
  }

  useEffect(() => {
    if (timeslots && timeslots.length > 0) {
      const formattedSelectedDate = formatDate(selectedDate, 'YYYY-MM-DD');
      const formattedCurrentDate = formatDate(new Date(), 'YYYY-MM-DD');
      const currentTime = getCurrentTimeFormatted();
      if (formattedSelectedDate === formattedCurrentDate) {
        const resTimeslots = timeslots.filter((timeslot) => timeslot.value >= currentTime);
        setfilteredTimeSlots(resTimeslots);
        if (resTimeslots.length > 0) {
          setSelectedTime(resTimeslots[0].value);
        }else{
          setSelectedTime([]);
        }
      } else {
        setfilteredTimeSlots(timeslots);
        setSelectedTime(timeslots[0].value);
      }
    }
  }, [timeslots]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" TransitionComponent={Transition}>
      <DialogTitle>
        <span style={{ fontWeight: 'bold' }}>{experienceName}</span>
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', right: 10, top: 10 }}
          onClick={close}
        >
          <MdOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className="wb-first-box">
          <Box>
            <Typography variant="subtitle2">Party Size</Typography>
            <Typography variant="subtitle1" style={{ paddingLeft: '15px' }}>{`${guestCount} Guest${
              guestCount !== 1 ? 's' : ''
            }`}</Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="decrement"
              onClick={handleDecrement}
              disabled={guestCount === 1}
            >
              <BiMinus />
            </IconButton>
            <IconButton aria-label="increment" onClick={handleIncrement}>
              <BiPlus />
            </IconButton>
          </Box>
        </Box>
        <Box className="wb-second-box">
          <Typography variant="subtitle2">Date</Typography>
        </Box>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="d MMM yyyy"
            minDate={new Date(minDate)}
            id="date-picker-inline"
            value={selectedDate}
            onChange={handleDateChange}
            shouldDisableDate={shouldDisableDate}
            maxDate={new Date(endDate ? endDate : '5000-01-01')}
            autoOk={true}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            InputProps={{ readOnly: true }}
            style={{
              width: '100%',
              paddingRight: '20px',
              paddingLeft: '20px',
              borderBottom: 'solid 1px #E7E7E7'
            }}
          />
        </MuiPickersUtilsProvider>
        <Box className="wb-third-box">
          <Typography variant="subtitle2">Time</Typography>
          <SelectComponent
            value={selectedTime}
            onChange={handleTimeChange}
            options={
              filteredTimeSlots && filteredTimeSlots.length > 0
                ? [...new Set(filteredTimeSlots.map((slot) => slot.value))].map((value) => ({
                    value,
                    text: filteredTimeSlots.find((slot) => slot.value === value).label
                  }))
                : []
            }
            className={classes.select}
            icon="time"
          />
        </Box>
        <Box marginTop={1} style={{ textAlign: 'justify' }}>
          {loadingChanges ? (
            <Grid
              container
              className={classes.fullWidthGrid}
              style={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div className="sweet-loading">
                <BounceLoader
                  css={classes.override}
                  size={30}
                  color={'#FE7D86'}
                  loading={loadingChanges}
                />
              </div>
            </Grid>
          ) : (
            <div>
              {displayHasNoShift && displayNoResultFound ? (
                <Typography variant="subtitle1">
                  Sorry there are currently no tables available at this time. Please select a
                  different date or time
                </Typography>
              ) : displayHasSpecialDay && displayNoResultFound ? (
                <Typography variant="subtitle1">
                  Sorry we are closed on this date. Please select a different date
                </Typography>
              ) : displayNoResultFound ? (
                <Typography variant="subtitle1">
                  Sorry there are currently no tables available at this time. Please select a
                  different date or time
                </Typography>
              ) : null}
              <Grid container justify={isXS ? 'space-evenly' : ''}>
                <Typography variant="subtitle1">
                  {promptMessage}
                </Typography>
                {bookingClose || filteredTimeSlots.length === 0 ? (
                  <Typography>
                    Currently, this event is unavailable. Kindly choose an alternative date or time.
                  </Typography>
                ) : (
                  availableTimeSlot &&
                  selectedDate &&
                  selectedTime &&
                  filteredTimeSlots.length > 0 &&
                  availableTimeSlot.map((timeslot) =>
                    timeslot.available
                      ? timeslot.floors.map((floor, floorIndex) => (
                          <Grid item key={floorIndex}>
                            <Button
                              onClick={() =>
                                handleCreateBooking(
                                  timeslot.shift_id,
                                  floor.id,
                                  floor.floor_name,
                                  floor.user_id,
                                  timeslot.label,
                                  timeslot.value
                                )
                              }
                              key={floor.id}
                              variant="contained"
                              className={classes.button}
                            >
                              <Box textAlign="center">
                                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                  {timeslot.label}
                                </Typography>
                                <Typography variant="caption">{floor.floor_name}</Typography>
                              </Box>
                            </Button>
                          </Grid>
                        ))
                      : null
                  )
                )}
              </Grid>
            </div>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BookingModal;
