import React from 'react';
import { Grid, Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const MessageDialog = React.memo(({ open, onClose, title, description }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>

      <Grid container justify="center" style={{ padding: '16px' }}>
        <Button variant="contained" onClick={onClose}>Close</Button>
      </Grid>
    </Dialog>
  );
});

export default MessageDialog;
