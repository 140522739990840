import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import './index.css';

import './assets/scss/tables.scss';

import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import Booking from './pages/booking/index';
import Experience from './pages/booking/experience';
import Schedule from './pages/roster/schedule';
import AvailabilityRequest from './pages/roster/availability-request';
const App = (props) => {
  return (
    <BrowserRouter>
      <Route path="/roster/schedule" exact component={Schedule} />
      <Route path="/roster/availability_request" exact component={AvailabilityRequest} />
      <Route path="/:key" exact component={Booking} />
      <Route path="/event/:experience_id/:key" exact component={Experience} />
      <Route path="/old/:key" exact component={Step1} />
      <Route path="/step2/:booking_id/:key" exact component={Step2} />
      <Route path="/step3/:key" exact component={Step3} />
    </BrowserRouter>
  );
};

const targetContainer = document.getElementById('root');

ReactDOM.render(<App />, targetContainer);
