import React, { useState, useEffect } from 'react';

//styles
import { Grid, Container, Typography, Box, Button } from '@material-ui/core';
import '../../assets/css/roster/app.css';

//hooks
import { getRoster } from '../../hooks/@roster/useRoster';

//utils
import { eachDayOfInterval, format } from 'date-fns';
import { useLocation } from 'react-router-dom';

const Schedule = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const schedule_id = searchParams.get('employee_schedule_date_id');
  const employee_id = searchParams.get('employee_id');
  const [myShift, setMyShift] = useState([]);
  const [fullName, setFullName] = useState('');
  const [roster, setRoster] = useState({
    data: null,
    status: null,
    error: null,
    isLoading: true
  });

  useEffect(() => {
    const fetchRosterData = async () => {
      try {
        const response = await getRoster(schedule_id, employee_id);
        switch (response.status) {
          case 200:
            setRoster({
              data: response.data,
              status: response.status,
              error: null,
              isLoading: false
            });
            break;
          default:
            // Handle other response statuses here
            break;
        }
      } catch (error) {
        setRoster({
          data: null,
          status: error.status,
          error: error.error,
          isLoading: false
        });
      }
    };

    fetchRosterData();
  }, [schedule_id, employee_id]);

  useEffect(() => {
    setMyShift([]);
    if (roster.data) {
      const dates = eachDayOfInterval({
        start: new Date(roster.data.calendar.start_date),
        end: new Date(roster.data.calendar.end_date)
      });

      const myShifts = roster.data.my_shift[employee_id];
      const userName = myShifts ? myShifts.first_name + ' ' + myShifts.last_name : '';
      setFullName(userName);
      dates.map((date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        const shiftsOnDate = myShifts.shifts[formattedDate];
        if (shiftsOnDate) {
          const shift = shiftsOnDate[0];
          setMyShift((prevShifts) => [
            ...prevShifts,
            {
              floorName: shift ? shift.floor_name : null,
              shiftTime: shift ? shift.shift_time : null,
              totalHours: shift ? shift.total_hours : null,
              date: format(date, 'EEEE do MMMM'),
              hasShift: shift ? true : false
            }
          ]);
        }
      });
    }
  }, [roster]);

  return (
    <div className="roster_root">
      <Container maxWidth="md">
        <Grid container className="roster_container">
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box className="roster_header">
              <Typography variant="h6" display="block" gutterBottom align="center">
                Hey { fullName } 👋
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom align="center">
                Here are your shifts at Santoni for the period of{' '}
                {roster.data ? roster.data.calendar.roster_date : ''}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {myShift.map((shift, index) =>
              shift.hasShift ? (
                <React.Fragment key={index}>
                  <Typography variant="subtitle1" display="block" gutterBottom>{ shift.date }</Typography>
                  <Box className="roster_card">
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
                        <Typography variant="subtitle2">{ shift.shiftTime }</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} xl={3} lg={3}>
                        <Typography variant="subtitle2">{ shift.floorName }</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} xl={3} lg={3}>
                        <Typography variant="subtitle2" className="roster_time">
                        { shift.totalHours }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment key={index}>
                  <Typography variant="subtitle1" display="block" gutterBottom style={{ color: '#667085' }}>{shift.date}</Typography>
                  <Box className="roster_card">
                    <Typography variant="subtitle2" style={{ color: '#667085', fontWeight: 'bold' }}>No Shift</Typography>
                  </Box>
                </React.Fragment>
              )
            )}

          </Grid>
          <Grid item>
            <Button variant="contained" className="roster_function_button">Save to Camera roll</Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Schedule;
